html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans JP", sans-serif;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.MuiTableRow-root .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
  margin-top: 0px;
}

@media print {
  button {
    display: none !important;
  }
}

